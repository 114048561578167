
import React from 'react'
import style from './contact.module.less'

const Contact = () => (
  <div className={`${style.container} light-contact`}>
        <h1>Contact</h1>
        <p>If you would like to contact me for anything, kindly shoot me a mail by filling the form below.</p>
        <form name="contact-form" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
          <div className={style.InputGroup}>
            <label htmlFor="contact-name">Name</label>
              <input name="name" id="contact-name" type="text" required className={style.Input} />
          </div>
          <div className={style.InputGroup}>
            <label htmlFor="contact-email">E-Mail </label>
              <input name="email" id="contact-email" type="email" required  className={style.Input} />
          </div>
          <div className={style.InputGroup}>
            <label htmlFor="contact-message">Your Message</label>
              <textarea name="message" id="contact-message" required className={style.Text} rows="4" />
          </div>
          <div>
            <button className={style.button}>Send</button>
          </div>
          <input type="hidden" name="form-name" value="contact-form" />
        </form>
      </div>
)

export default Contact