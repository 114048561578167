export default [

    {
        id: 1,
        image: 'https://dev-to-uploads.s3.amazonaws.com/i/g6t50bxfopwvmt0kx8pn.png',
        description: `An open-source project designed to help developers store, and organize code snippets for easy access`,
        title: 'Codelify',
        github: 'https://github.com/Codelify',
        website: 'https://codelify.dev/',
        stack: ['React', 'Node', 'GraphQL']
    },
    {
        id: 2,
        image: 'https://dev-to-uploads.s3.amazonaws.com/i/w8znxko7tcz2an4lez99.png',
        description: `An app designed to help
        improve team performance by giving and receiving feedback anonymously from teammates`,
        title: 'Anonymous team feedback',
        github: 'https://github.com/labseu2-anonymous-team-feedback',
        website: 'https://anonymous-feedback-fe.herokuapp.com/',
        stack: ['React', 'Node', 'GraphQL']
    },
    
    {
        id: 3,
        image: 'https://dev-to-uploads.s3.amazonaws.com/i/r1iaane34la0jolwrgy3.png',
        description: `An app designed for connecting users in need of renting equipment/properties with the users willing to rent out same.`,
        title: 'Use My Tech Stuff',
        github: 'https://github.com/build-week-use-my-tech-stuff-umts/umts-backend',
        website: 'https://github.com/build-week-use-my-tech-stuff-umts/umts-backend',
        stack: ['Node', 'Express', 'Coveralls', 'Travis CI']
    },
    {
        id: 4,
        image: 'https://dev-to-uploads.s3.amazonaws.com/i/f8xgju87pxu6qpl6gnn3.png',
        description: `An app I built using pure HTML, CSS and JavaScript`,
        title: 'Tieme Ndo',
        github: 'https://github.com/tiemendo/Tiemendo-User-Interface-CHINEDU',
        website: 'https://tiemendo.netlify.com/index.html',
        stack: ['HTML', 'CSS', 'LESS', 'JavaScript']
    },

    {
        id: 5,
        image: 'https://dev-to-uploads.s3.amazonaws.com/i/7v96xalx69myc1zjcg1j.png',
        description: `Real Estate Collabo gives you the opportunity to invest in real estate with any amount you have.`,
        title: 'Real Estate Collabo',
        github: '#',
        website: 'https://www.realestatecollabo.com/',
        stack: ['React', 'Node', 'SASS', 'JavaScript']
    },
    
    
    
]