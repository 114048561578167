import React from 'react'
import styles from './project.module.less'
import { FaGithub, FaGlobe } from 'react-icons/fa'
import data from './data'

export default function() {

  const gotoPage = (link) => {
    window.location.href = link;
  }
  return (
    <div className={`${styles.projects} light-project`}>
      <h2>Projects</h2>
      <div className={styles.content}>
        {data.map(project => (
          <div className={styles.project} key={project.id} onClick={ () => gotoPage(project.website) } role="button">
            <div className={styles.description}>
              <img src={project.image} alt={`${project.title}`} />
              <div className={styles.overlay}>
                <p className={styles.text}>{project.description}</p>
                <p>{project.stack.join(' | ')}</p>
                <div className={styles.links}>
                  <a href={project.github}>
                    <FaGithub size="30" />
                  </a>
                  <a href={project.website}>
                    <FaGlobe size="30" />
                  </a>
                </div>
              </div>
            </div>
            <a href={project.website}>
              <h5>{project.title}</h5>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
