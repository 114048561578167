/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
/* App imports */
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Utils from '../../utils'
import * as style from './index.module.less'
import Contact from '../../components/layout/contact'
import Projects from '../../components/layout/projects'
import Config from '../../../config'

const Portfolio = ({ data: { profilePhoto, skillIcons, toolIcons } }) => {
  return (
    <Layout>
      <SEO
        title="Portfolio"
        description="Chinedu Orie - Software Engineer"
        path="portfolio"
        keywords={[
          'oriechinedu',
          'chinedu orie',
          'orie chinedu',
          'orie chinedu emmanuel',
          'nedsoft',
        ]}
      />
      <div className={style.container}>
        <div className={style.left}>
          <div className={`${style.photo} light-photo`}>
            <Img fluid={profilePhoto.childImageSharp.fluid} />
          </div>
          <ul>
            <li>
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href={Config.social.github}
                aria-label="Link to my GitHub repository"
              >
                <FaGithub size="30" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href="https://dev.to/nedsoft"
                aria-label="Link to my Dev.to page"
              >
                <img
                  src="https://d2fltix0v2e0sb.cloudfront.net/dev-badge.svg"
                  alt="Chinedu Orie's DEV Profile"
                  height="30"
                  width="30"
                />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href={Config.social.linkedin}
                aria-label="Link to my Linkedin profile"
              >
                <FaLinkedin size="30" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="nofollow noopener noreferrer"
                href={Config.social.twitter}
                aria-label="Link to my twitter account"
              >
                <FaTwitter size="30" />
              </a>
            </li>
          </ul>
        </div>

        <div className={`${style.content} portfolio-content`}>
          <h1>Hi, I&apos;m Chinedu Orie</h1>
          <h2>Software Engineer</h2>
          <p>
            I am a Software Engineer with experience in building full-scale web
            applications. I excel at every stage of the software development
            life cycle (SDLC) including requirement analysis, architectural
            design, coding, testing, deployment and maintenance. I do not just
            write code that works, I pay keen attention to optimisation and
            security of a software solution.
          </p>
          <div>
            <h4>A brief tour on my professional history</h4>
            <p>
              As a child I loved computers and was enthusiastic about the{' '}
              <i>magics</i> that it does. That enthusiasm and curiosity led me
              to opting for sciences in high school and eventually studied
              Computer Engineering at Nnamdi Azikiwe University, Awka where I
              emerged as the Best Graduating Student of the department for the
              class of 2015/2016. While I was at the university, I was
              introduced to a couple of programming languages including Java,
              C++, C and Assembly language but eventually got specialized in
              embedded C and Assembly language for embedded systems designs and
              implementations. I designed and implemented a number of smart
              systems; most prominent among them was my final year project which
              landed me a contract negotiation with the CEO of GeoGold hotel in
              Awka who wanted me to install the system in his hotel.
            </p>
            <p>
              Upon graduation, I was faced with the challenge of the capital
              intensive nature of building hardware that can thrive in the
              market, and as one who managed to fend for myself at the
              university, I decided to delve into software development whose
              requirements were within my reach. Since I made the decision to
              toe the path of software engineering in 2016, I’ve given myself
              completely to it because for me, when I commit myself to a thing I
              go all in it to get the best results.
            </p>
            <p>
              So far, I have worked with great companies such as [Hotels.ng](https://hotels.ng.),
              [Andela](https://andela.com) and [Lambda School](https://lambdaschool.com) who have helped shape my career in the
              software engineering journey. I have worked as a part of a team
              and as an individual to build quality software solutions that
              serve many users. I have keen interest in Fintech industry both
              Fiat and cryptocurrency focused Fintechs. When I am not writing
              code, I love to play table tennis, write technical articles or
              read philosophical and business books.
            </p>
            <p> There’s never a boring
              moment in a team I work with, I’ve got a natural endowment to keep
              a team alive!</p>
          </div>
          <br />
        </div>
        <div className={`${style.SkillSection} light-skill`}>
          <h2>Skills</h2>
          <ImageList edges={skillIcons.edges} />
          <h2>Tools</h2>
          <ImageList edges={toolIcons.edges} />
        </div>
        <Projects />
        <Contact />
      </div>
    </Layout>
  )
}

Portfolio.propTypes = {
  data: PropTypes.shape({
    profilePhoto: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    skillIcons: PropTypes.object.isRequired,
    toolIcons: PropTypes.object.isRequired,
  }),
}

const ImageList = ({ edges }) => (
  <div className={style.iconsContainer}>
    {edges
      .sort((edgeA, edgeB) =>
        edgeA.node.name.toLowerCase() > edgeB.node.name.toLowerCase() ? 1 : -1
      )
      .map(({ node: { name, childImageSharp } }) => (
        <div className={`${style.iconWrapper} light-icon-wrapper`} key={name}>
          <Img
            fixed={childImageSharp.fixed}
            alt={name + ' logo'}
            title={name}
          />
          <label>
            {iconsNameMap[name] ? iconsNameMap[name] : Utils.capitalize(name)}
          </label>
        </div>
      ))}
  </div>
)

ImageList.propTypes = {
  edges: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        name: PropTypes.string.isRequired,
        childImageSharp: PropTypes.shape({
          fixed: PropTypes.object.isRequired,
        }).isRequired,
      }).isRequired,
    })
  ).isRequired,
}

export const query = graphql`
  {
    profilePhoto: file(name: { eq: "profile-photo" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    skillIcons: allFile(filter: { dir: { regex: "/portfolio/skills$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
    toolIcons: allFile(filter: { dir: { regex: "/portfolio/tools$/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 50) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    }
  }
`
// Use to set specific icons names
const iconsNameMap = {
  css: 'CSS',
  html: 'HTML',
  jquery: 'JQuery',
  nodejs: 'Node.js',
  vuejs: 'Vue.js',
}

export default Portfolio
